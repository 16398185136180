export const SUBMITTING_AS = 'Is this Plus One Achievement about you, or are you celebrating a coworker?'
export const SUPPORT_TYPE = 'Is this Plus One event something that happened directly with an external customer, or is it internal to Entergy, but indirectly affected a customer?'
export const PLUS1_TITLE= 'Give the Plus One Achievement a nice name, something descriptive and short. (ie. Jamie changed a tire and saved a stranded family)'
export const PLUS1_DESC = 'Let us know what happened! Give us some great details about what happened and how our customers responded.'
export const SUBMIT_PLUS1 = 'Plus Ones are reviewed by your manager.'
export const EMP_SEARCH = 'Please type the name of the employee you want to recognize for their Plus One Achievement, and review their information below.'

export const FOCUS_TABLE = 'This Plus One has been marked for leadership review.'
export const IS_PLUS_ONE = 'Acceptable Plus Ones provide clear impact either indirectly or directly to one or more of our customers or have helped us avoid a negative experience which would hurt our net promoter score. Doing a good job on a regular job duty is not considered a Plus One.'
export const SHOULD_FOCUS = 'This option marks the entry for leadership attention on entries that deserve awards and recognition, or would assist in continuous improvements.'
export const COMMENTS = 'Comments can be seen by leadership. Employees will be able to see comments only upon rejection.'
export const SUMBIT_MANAGER = 'Submissions are final and cannot be changed. Employees are not notified directly for acceptance. Only the Employee who has submitted the entry will be notified of a rejection.'
export const DOWNLOAD_CSV = "Download a CSV file containing the Plus Ones displayed in the Manager Dashboard."
export const DOWNLOAD_CSV_REPORT = "Download a CSV file containing the Plus Ones displayed in the Data Grid"