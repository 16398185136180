import { 
  Container,
  Box,
  Typography,
  Button,
  Link,
  Grid,
  Hidden
}
from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header:{
    padding: theme.spacing(1),
    display: 'flex',
    height: '450px',
    marginBottom: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(244, 244, 244)',
    backgroundImage: 'url("/powerplusonebanner.jpg")',
    backgroundPosition: '0px 0px',
    backgroundSize: 'cover',
    color: 'rgb(255, 255, 255)',
    [theme.breakpoints.down('sm')]: {
      height: '475px',
    }
  },
  infoButton: {
    backgroundColor: 'rgb(255, 255, 255)',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 35px',
      lineHeight: '26px',
    },
    fontSize: '14px',
    borderRadius: '32px',
    borderColor: 'rgba(204, 204, 204, 1)',
  },
  learnMoreLink: {
    color: 'white',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  infoText: {
    opacity: '0.6',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    fontSize: 25,
    color: 'white' 
  },
  startText: {
    opacity: '0.6',
    letterSpacing: '1px'
  },
  bottomText: {
    width: '50%',
  }
}));

/**
 * PlusOneHeader component.
 */
const PlusOneHeader = () => {
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Box m={1} className={classes.header}>
        <Grid container spacing={3}>
          <Grid item xs={12} align="center">
            <Typography component="h1" variant="h2">
            Plus One
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography component="h1" variant="h5" className={classes.infoText}>
              The Plus One Program aims to highlight where we are exceeding customer expectations today to better shape how we define customer centricity for tomorrow.
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Link id="learn-more-link" className={classes.learnMoreLink} href='https://web.microsoftstream.com/channel/df385295-733d-4606-84a6-239c2ee2e559' target="_blank" rel="noreferrer"> 
              <Typography component="h1" variant="h5" align="center" className={classes.infoText}>
                Learn More
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} align="center">
            <Button id="info-button" size="large" className={classes.infoButton} variant="contained" href='#start-here'> 
            Get Started
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Typography id='start-here' component="h1" variant="overline" align="center" className={classes.startText}>
          Start Here
      </Typography>
      <Hidden xsDown>
        <Box mb={8} align="center">
          <Typography component="h1" variant="h4" className={classes.bottomText}>
            Take a moment to recognize outstanding customer centricity with Plus One
          </Typography>
        </Box>
      </Hidden>
    </Container>
  )
}

export default PlusOneHeader;
