import { useState } from 'react';
import ClientOAuth2 from 'client-oauth2';
import { setRequestHeader } from '../../api/APIUtils';
import { getEmployeeInfo } from '../../api/EmployeeAPI';

const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
const appUrl = process.env.REACT_APP_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const accessTokenUri = process.env.REACT_APP_TOKEN_URL;
const authorizationUri = process.env.REACT_APP_AUTH_URL;
const redirectUri = process.env.REACT_APP_REDIRECT_URL;

function setAuthorizationHeader(token) {
    setRequestHeader(token.id_token);
}

export function AuthService() {
    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [errorType, setErrorType] = useState(null);

    let fetchingInfo = false;
    let userAuth = new ClientOAuth2({
      clientId: clientId,
      accessTokenUri: accessTokenUri,
      authorizationUri: authorizationUri,
      redirectUri: redirectUri
    });
  
    const getLoginUrl = (previousPath) => {
      const uri = userAuth.code.getUri({ "state" : previousPath });
      return uri
    };
  
    const receiveLogin = () => {
      // Use this fetchingInfo to try and reduce the number of requests we make. We should only make one request per
      // login
      if(!fetchingInfo) {
        fetchingInfo = true;
        userAuth.code.getToken(document.URL).then(function(retToken) {
            setAuthorizationHeader(retToken.data);
            setUser(retToken);
            getEmployeeInfo((data) => {
              setUserInfo(data);
              fetchingInfo = false;
            })
            .catch((error) => {
              console.log("Error getting user info: " + error.message);
              setHasError(true);
              setErrorType(error);
            });
        }, function(err) {
            console.log("Error receiving OAuth login");
            console.log(JSON.stringify(err));
            if(err["body"])
              setErrorType(err["body"]["error"]);
            setHasError(true);
        })
      }
    };
  
    const logout = () => {
        setUser(false);
        setUserInfo(null);
        setAuthorizationHeader("");
    };

    const getLogoutUrl = () => {

      let myLogoutUrl = "{0}?response_type=code&client_id={1}&redirect_uri={2}"
            .replace("{0}", logoutUrl)
            .replace("{1}", clientId)
            .replace("{2}", encodeURI(appUrl));
      
      return myLogoutUrl;
    };
 
    return {
      user,
      setUser,
      userInfo,
      hasError,
      errorType,
      setUserInfo,
      userAuth,
      getLoginUrl,
      receiveLogin,
      logout,
      getLogoutUrl
    }
  }