import axios from 'axios';

const APIUtils = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

function setRequestHeader(token) {
  APIUtils.interceptors.request.use(req => {
    
    if(token)
      req.headers.Authorization = "Bearer {}".replace("{}", token);
    else
      req.headers.Authorization = "";

    return req;
  });
}

export {
  setRequestHeader,
  APIUtils
}