import { 
  Container,
  Grid,
  Menu as MenuTag,
  IconButton,
  MenuItem
 } 
from "@material-ui/core";

import Menu from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import UserInfo from "./UserInfo";
import { AuthContext } from '../context/authContext';
import ProgressIndicator from './ProgressIndicator';
import logo_small from '../assets/img/entergy_h_w_2021.png';
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  container:{
    padding: '15px 25px 10px 10px',
    background: '#212121',
    color: grey[50]
  }
}));
const NavBar = () => {
  const [menuElem, setMenuElem ] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  const { userInfo } = useContext(AuthContext);

  const handleMenuClick = (event) => {
    setMenuElem(event.currentTarget);
  }

  const handleMenuItemClick = (event) => {
    if(event.currentTarget.dataset.link) {
      history.push(event.currentTarget.dataset.link);
    }
  }

  const handleMenuClose = () => {
    setMenuElem(null);
  }

  const isManager = () => {
    return (userInfo && userInfo.directReportsCount && userInfo.directReportsCount > 0);
  }

  const isAdmin = () => {
    return (userInfo && userInfo.admin === "1")
  }

  const openInNewTab = () => {
    const newWindow = window.open(process.env.REACT_APP_POWER_BI_URL)
    if (newWindow) newWindow.opener = null
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container>
        <Grid container item xs={6} justify="flex-start" alignItems="center">
          <img src={logo_small} width={170} height={44} alt="Entergy Logo"></img>
        </Grid>
        <Grid container item xs={6} justify="flex-end" alignItems="center">
          <UserInfo />
          <IconButton onClick={handleMenuClick}>
            <Menu style={{ color: grey[50] }}/>
          </IconButton>
          <MenuTag 
            anchorEl={menuElem}
            id="nav-menu"
            keepMounted
            open={Boolean(menuElem)}
            onClose={handleMenuClose}
            >
            <ProgressIndicator type="small" loading={!userInfo}>
              <MenuItem onClick={handleMenuItemClick} data-link="/">Home</MenuItem>
              { isManager() ? <MenuItem onClick={handleMenuItemClick} data-link="/manager/directreports">Manager Dashboard</MenuItem> : '' }
              <MenuItem onClick={handleMenuItemClick} data-link="/myplusone">My Approved Plus Ones</MenuItem>  
              { isManager() ? <MenuItem onClick={openInNewTab}>PowerBI Report</MenuItem> : '' }               
              { isAdmin() ? <MenuItem onClick={handleMenuItemClick} data-link="/reports">Reporting</MenuItem> : '' }
              <MenuItem onClick={handleMenuItemClick} data-link="/logout">Logout</MenuItem>
            </ProgressIndicator>
          </MenuTag>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NavBar;