import ManagerForm from '../components/ManagerForm';
import BasicView from './BasicView';
const ManagerView = () => {
  return (
    <div style={{backgroundColor: "#DBDBDB" }}>
      <BasicView>
        <ManagerForm />
      </BasicView>
    </div>
  )
}

export default ManagerView;