import MyPlusOneForm from '../components/MyPlusOneForm';
import BasicView from './BasicView';
const MyPlusOneView = () => {
  return (
    <div style={{backgroundColor: "#DBDBDB" }}>
      <BasicView>
          <MyPlusOneForm />
      </BasicView>
    </div>
  )
}

export default MyPlusOneView;