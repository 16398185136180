export const customerTypes = [
  {
    value: 'Commercial Customer',
    label: 'Commercial Customer'
  },
  {
    value: 'Governmental Customer',
    label: 'Governmental Customer'
  },
  {
    value: 'Industrial Customer',
    label: 'Industrial Customer'
  },
  {
    value: 'Internal Customer',
    label: 'Internal Customer'
  },
  {
    value: 'Multiple Types of Customers',
    label: 'Multiple Types of Customers'
  },
  {
    value: 'Residential',
    label: 'Residential'
  }
];
