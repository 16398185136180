export const highlightReasons = [
  {
      value: 'Continuous Improvement Opportunity',
      label: 'Continuous Improvement Opportunity'
  },
  {
      value: 'Excellence in Customer Centricity Nomination',
      label: 'Excellence in Customer Centricity Nomination'
  }
  
]