import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route } from "react-router-dom";

import PlusOneView from './views/PlusOneView';
import ManagerView from './views/ManagerView';
import MyPlusOneView from './views/MyPlusOneView';

import { AuthContext } from './context/authContext';
import OauthSend from './components/Oauth/OauthSend';
import OauthReceive from './components/Oauth/OauthReceive';
import OauthLogout from './components/Oauth/OauthLogout';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthService } from './components/Oauth/utils';
import theme from './theme';

import TagManager from 'react-gtm-module';
import PlusOneReportView from './views/PlusOneReportView';

import Banner from 'react-js-banner';

const styles = (theme) => ({
  bannerTop: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: '20px',
    marginBottom: '0',
    top: '0',
    position: 'sticky',
    zIndex: '99'
  },
  bannerBottom: {
    color: 'white',
    backgroundColor: 'red',
    fontSize: '20px',
    marginBottom: '0',
    bottom: '0',
    position: 'sticky',
    zIndex: '99'
  },
  link: {
    color: 'white'
  }
});

if (process.env.REACT_APP_ENV === 'prod') {

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_GTAG_KEY
  }
  TagManager.initialize(tagManagerArgs)
}


function ProvideAuth({ children }) {
  const auth = AuthService();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

const App = () => {
  const classes = styles();

  const BannerTitle = () => {
    return( 
      <div>
        This is not the official production site for PlusOne. Adding a plus one here is for testing purposes only. Please go to <a style={classes.link} href='https://plusone.entergy.com'>plusone.entergy.com</a> to enter a PlusOne.
      </div>
    );
  }
  
  return (
    <div>
      <ProvideAuth>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Banner 
            title={<BannerTitle />} 
            css={classes.bannerTop} 
            showBanner={process.env.REACT_APP_ENV !== 'prod'}
          />
          <Switch>
            <ProtectedRoute path={["/manager/:view", "/manager/:id/:view"]}>
              <ManagerView />
            </ProtectedRoute>
            <ProtectedRoute path="/myplusone">
              <MyPlusOneView />
            </ProtectedRoute>
            <Route path="/authcallback">
              <OauthReceive />
            </Route>
            <Route path="/login" >
              <OauthSend />
            </Route>
            <Route path="/logout">
              <OauthLogout />
            </Route>
            <ProtectedRoute path="/reports">
              <PlusOneReportView />
            </ProtectedRoute>
            <ProtectedRoute path="/">
              <PlusOneView />
            </ProtectedRoute>

          </Switch>
          <Banner 
            title={<BannerTitle />} 
            css={classes.bannerBottom} 
            showBanner={process.env.REACT_APP_ENV !== 'prod'}
          />
        </ThemeProvider>
      </ProvideAuth>
    </div>
  );
}

export default App;
