import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import { AuthContext } from '../context/authContext';

class ProtectedRoute extends React.Component {

    render() {
        const auth = this.context;

        if(auth.user) {
            return (
                <Route
                    render={() => (this.props.children) }
                />);
        }
        else {
            const url = "/login?redirect={}".replace("{}", window.location.pathname);
            return (<Redirect to={url}></Redirect>);
        }
    }
}

ProtectedRoute.contextType = AuthContext;
export default ProtectedRoute;