import PlusOneHeader from './../components/PlusOneHeader';
import PlusOneForm from './../components/PlusOneForm';
import BasicView from './BasicView';
/**
 * PlusOneView contains all the components used for displaying.
 */
const PlusOneView = () => {
  return (
    <BasicView>
      <PlusOneHeader />
      <PlusOneForm/>
    </BasicView>
  );
}

export default PlusOneView;
