import { useState, useContext, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Grid,
  Typography,
  Box,
  Snackbar,
  CircularProgress
}
  from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EmployeeSearch from './EmployeeSearch';
import PlusOneSuccessDialog from './PlusOneSuccessDialog';
import PlusOneTooltip from './PlusOneTooltip';
import { customerTypes } from './../constants/customerTypes';
import * as toolTipLabels from './../constants/labels';
import { createPlusOne } from '../api/PlusOneEntryAPI';
import { Alert } from '@material-ui/lab';
import { AuthContext } from '../context/authContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer:{
    paddingLeft: "15%",
    paddingRight: "15%",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: "5%",
      paddingRight: "5%"
    }
  },
  helperText: {
    textAlign: "right"
  },
  submitButton: {
    //backgroundColor: 'rgb(255, 255, 255)',
    [theme.breakpoints.up('sm')]: {
      padding: '16px 35px',
      lineHeight: '26px',
    },
    fontSize: '14px',
    borderRadius: '32px',
    borderColor: 'rgba(204, 204, 204, 1)',
  },
  helperLabel: {
    marginBottom: '-10px',
  }
}));

/**
 * Main component of form.
 * @param {*} props 
 */
const PlusOneForm = (props) => {
  const classes = useStyles();
  const TITLE_CHARACTER_LIMIT = 100;
  const DESC_CHARACTER_LIMIT = 1000;
  const initialState = {
    submitterFirstName: '',
    submitterLastName: '',
    submitterEIN: '',
    submitterTitle: '',
    submitterEmail: '',
    submitterManager: '',
    submitterManagerEmail: '',
    submitterDepartment: '',
    employeeFirstName: '',
    employeeLastName: '',
    employeeEIN: '',
    employeeTitle: '',
    employeeEmail: '',
    employeeManager: '',
    employeeManagerEmail: '',
    employeeDepartment: '',
    supervisorEin: '',
    plusOneTitle: '',
    plusOneDescription: '',
    submittingAs: 'myself',
    customerType: '',
    plusOneType: '',
    plusOneDate: new Date()
  }
  const [plusOneForm, setPlusOneForm] = useState(initialState);
  const [submitIndicator, setSubmitIndicator] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  /**
   * Handle closing of successfully submitted message.
   * 
   * @param {} event 
   * @param {*} reason 
   */
  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    //reset to initial state after submit
    setPlusOneForm(initialState);
  };

  /**
   * Handle closing of error on submit message.
   * 
   * @param {} event 
   * @param {*} reason 
   */
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  /**
   * Handle form submission. Save
   * @param {*} event 
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitIndicator(true);
    if (plusOneForm.submittingAs === 'myself') {
      //if submitting as self, copy values from submitter info
      plusOneForm.employeeFirstName = plusOneForm.submitterFirstName;
      plusOneForm.employeeLastName = plusOneForm.submitterLastName;
      plusOneForm.employeeEIN = plusOneForm.submitterEIN;
      plusOneForm.employeeTitle = plusOneForm.submitterTitle;
      plusOneForm.employeeEmail = plusOneForm.submitterEmail;
      plusOneForm.employeeManager = plusOneForm.submitterManager;
      plusOneForm.employeeManagerEmail = plusOneForm.submitterManagerEmail;
      plusOneForm.employeeDepartment = plusOneForm.submitterDepartment;
      plusOneForm.supervisorEin = plusOneForm.submitterManagerEIN;
    }

    createPlusOne(plusOneForm)
      .then((response) => {
        if (response.status === 200) {
          setSubmitIndicator(false);
          setOpenSuccess(true);
        }
      }, (error) => {
        setSubmitIndicator(false);
        setOpenError(true);
      })
      .catch(console.log);
  }

  /**
   * Update state depending on which field was changed. 
   * Used for TextFields and Radios.
   * 
   * @param {*} event 
   */
  const handleChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setPlusOneForm({
      ...plusOneForm,
      [key]: value
    });
  };

  const handlePlusOneDateChange = (newDate) => {
    setPlusOneForm({
      ...plusOneForm,
      plusOneDate: newDate
    });
  };

  const handleSubmittingAsChange = (event) => {
    let value = event.target.value;
    if (value === 'myself') {
      setPlusOneForm({
        ...plusOneForm,
        employeeFirstName: '',
        employeeLastName: '',
        employeeEIN: '',
        employeeTitle: '',
        employeeEmail: '',
        employeeManager: '',
        employeeManagerEmail: '',
        employeeDepartment: '',
        submittingAs: value
      });
    } else {
      setPlusOneForm({
        ...plusOneForm,
        submittingAs: value
      });
    }
  }

  const handleEmployeeChange = (event, selectedEmployee) => {
    if (selectedEmployee) {
      setPlusOneForm({
        ...plusOneForm,
        employeeFirstName: selectedEmployee.firstName,
        employeeLastName: selectedEmployee.lastName,
        employeeEIN: selectedEmployee.id,
        employeeTitle: selectedEmployee.title,
        employeeEmail: selectedEmployee.email,
        employeeManager: selectedEmployee.supervisorName,
        employeeManagerEmail: selectedEmployee.supervisorEmail,
        employeeDepartment: selectedEmployee.department,
        supervisorEin: selectedEmployee.supervisorEin
      });
    } else {
      setPlusOneForm({
        ...plusOneForm,
        employeeFirstName: '',
        employeeLastName: '',
        employeeEIN: '',
        employeeTitle: '',
        employeeEmail: '',
        employeeManager: '',
        employeeManagerEmail: '',
        employeeDepartment: '',
        employeeManagerEin: ''
      });
    }
  }

  //get current logged in user info from context
  const { userInfo } = useContext(AuthContext);
  useEffect(() => {
    //if user details have been loaded via context, set submitter info;
    if (userInfo) {
      plusOneForm.submitterFirstName = userInfo.firstName;
      plusOneForm.submitterLastName = userInfo.lastName;
      plusOneForm.submitterEIN = userInfo.id;
      plusOneForm.submitterTitle = userInfo.title;
      plusOneForm.submitterEmail = userInfo.email;
      plusOneForm.submitterManager = userInfo.supervisorName;
      plusOneForm.submitterManagerEmail = userInfo.supervisorEmail;
      plusOneForm.submitterManagerEIN = userInfo.supervisorEin;
      plusOneForm.submitterDepartment = userInfo.department;
    }
  })

  return (
    <div>
      <Container component="main" maxWidth={false} className={classes.mainContainer}>

        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>

            {/* Submitting as radio */}
            <Grid item sm={6} xs={12}>
              <FormControl component="fieldset" margin='normal' required>
                <PlusOneTooltip title={toolTipLabels.SUBMITTING_AS} placement="top-end" arrow>
                  <FormLabel component="legend">Submitting as</FormLabel>
                </PlusOneTooltip>
                <RadioGroup aria-label="submittingAs" name="submittingAs" value={plusOneForm.submittingAs} onChange={handleSubmittingAsChange} row>
                  <FormControlLabel value="myself" control={<Radio />} label="Myself" />
                  <FormControlLabel value="observer" control={<Radio />} label="An Observer" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {/* Employee info componnent - only displayed if submitting as 'observer' is selected */}
          {plusOneForm.submittingAs === 'observer' &&
            <EmployeeInfo onChangeHandler={handleChange} onEmployeeChange={handleEmployeeChange} formInfo={plusOneForm} />
          }

          <Grid container spacing={3}>
            <Grid item xs={12} align="left">
              <Box mt={6} >
                <Typography component="h1" variant="h5">
                  Tell us about the event!
                </Typography>
              </Box>
            </Grid>

            {/* Type as radio */}
            <Grid item sm={12} xs={12}>
              <FormControl component="fieldset" margin='normal' required fullWidth>
                <PlusOneTooltip title={toolTipLabels.SUPPORT_TYPE} placement="top-end" arrow>
                  <FormLabel component="legend">Type of Customer Engagement</FormLabel>
                </PlusOneTooltip>
                <RadioGroup aria-label="plusOneType" name="plusOneType" onChange={handleChange} value={plusOneForm.plusOneType} row>
                  <FormControlLabel value="Indirect Internal Engagement" control={<Radio required />} label="Indirect Internal Engagement" />
                  <FormControlLabel value="Direct External Engagement" control={<Radio required />} label="Direct External Engagement" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Date and time date picker */}
            <Grid item lg={4} md={5} sm={6} xs={12}>
              <FormLabel component="legend" className={classes.helperLabel}>When did this plus one happen?</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  disableToolbar
                  variant="inline"
                  InputAdornmentProps={{ position: "start" }}
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  name="plusOneDate"
                  value={plusOneForm.plusOneDate}
                  onChange={handlePlusOneDateChange}
                  disableFuture='true'
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    "data-testid": "plusOneDate-testid"
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            {/* Customer type text field */}
            <Grid item lg={8} md={7} sm={6} xs={12}>
              <FormLabel component="legend" className={classes.helperLabel} >Who were the affected customer(s)?</FormLabel>
              <TextField
                variant="outlined"
                margin="normal"
                onChange={handleChange}
                value={plusOneForm.customerType}
                fullWidth
                required
                id="customer-type-select"
                //label="Who were the affected customers?"
                name="customerType"
                select
                inputProps={{
                  "data-testid": "customerType-testid"
                }}
              >
                {customerTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>



            {/* Plus one title text field */}
            <Grid item sm={12} xs={12}>
              <FormLabel component="legend" className={classes.helperLabel}>Entry Title</FormLabel>
              <PlusOneTooltip title={toolTipLabels.PLUS1_TITLE} placement="top-end" arrow>
                <TextField
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  value={plusOneForm.plusOneTitle}
                  helperText={`${plusOneForm.plusOneTitle.length}/${TITLE_CHARACTER_LIMIT}`}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  inputProps={{
                    maxLength: TITLE_CHARACTER_LIMIT,
                    "data-testid": "plusOneTitle-testid"
                  }}
                  fullWidth
                  required
                  id="plusOneTitle"
                  //label="Entry Title"
                  name="plusOneTitle"
                  autoComplete="plusOneTitle"
                />
              </PlusOneTooltip>
            </Grid>

            {/* Plus one description text field */}
            <Grid item sm={12} xs={12}>
              <FormLabel component="legend" className={classes.helperLabel}>Description</FormLabel>
              <PlusOneTooltip title={toolTipLabels.PLUS1_DESC} placement="top-end" arrow>
                <TextField
                  variant="outlined"
                  margin="normal"
                  onChange={handleChange}
                  value={plusOneForm.plusOneDescription}
                  helperText={`${plusOneForm.plusOneDescription.length}/${DESC_CHARACTER_LIMIT}`}
                  FormHelperTextProps={{
                    className: classes.helperText
                  }}
                  inputProps={{
                    maxLength: DESC_CHARACTER_LIMIT,
                    className: classes.textArea,
                    "data-testid": "plusOneDesc-testid"
                  }}
                  fullWidth
                  required
                  multiline
                  rows={6}
                  id="plusOneDescription"
                  //label="Description"
                  name="plusOneDescription"
                  autoComplete="plusOneDescription"
                />
              </PlusOneTooltip>
            </Grid>
          </Grid>

          {/* Plus one form submit button */}
          <PlusOneTooltip title={toolTipLabels.SUBMIT_PLUS1} placement="top-end" arrow>
            <Button id="plus-one-submit" className={classes.submitButton} type="submit" color="primary" variant="contained">
              {!submitIndicator && "Submit"}
              {submitIndicator && <CircularProgress color="secondary" />}
            </Button>
          </PlusOneTooltip>
        </form>

        {/* Success modal on submit */}
        <PlusOneSuccessDialog open={openSuccess} onClose={handleSuccessClose} />

        {/* Error message on submit*/}
        <Snackbar open={openError} autoHideDuration={5000} onClose={handleErrorClose}>
          <Alert onClose={handleErrorClose} severity="error">
            Error submitting Plus One
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}

/**
 * Contains all employee info details.
 * @param {*} props 
 */
const EmployeeInfo = (props) => {
  const { onChangeHandler, onEmployeeChange, formInfo } = props;
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} xs={12} align="left">
          <Box mt={6} >
            <Typography component="h1" variant="h5">
              Who should we celebrate?
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <EmployeeSearch onChangeHandler={onEmployeeChange} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            variant="filled"
            margin="normal"
            name="employeeFirstName"
            label="First Name"
            onChange={onChangeHandler}
            value={formInfo.employeeFirstName}
            fullWidth
            required
            inputProps={{
              readOnly: true,
              "data-testid": "firstName-testid"
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            variant="filled"
            margin="normal"
            name="employeeLastName"
            label="Last Name"
            onChange={onChangeHandler}
            value={formInfo.employeeLastName}
            fullWidth
            required
            inputProps={{
              readOnly: true,
              "data-testid": "lastName-testid"
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            variant="filled"
            margin="normal"
            name="employeeEmail"
            label="Email"
            onChange={onChangeHandler}
            value={formInfo.employeeEmail}
            fullWidth
            required
            inputProps={{
              readOnly: true,
              "data-testid": "email-testid"
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            variant="filled"
            margin="normal"
            name="employeeManager"
            label="Manager's Full Name"
            onChange={onChangeHandler}
            value={formInfo.employeeManager}
            fullWidth
            required
            inputProps={{
              readOnly: true,
              "data-testid": "managerFullName-testid"
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default PlusOneForm;
