import React from 'react'
import { getSubordinatesPlusOnes, getAllSubordinatesPlusOnes } from '../api/PlusOneEntryAPI';
import { AuthContext } from '../context/authContext';
import { DataGrid } from '@material-ui/data-grid';
import { Check, Close, MoreVert, NotificationsActive, Star, StarBorder, Search, GetApp } from '@material-ui/icons';
import { Button, Chip, Typography, Grid, MenuItem, MenuList, TextField, InputAdornment, withStyles, Snackbar } from '@material-ui/core';
import PlusOneDetailView from './PlusOneDetailView';
import ProgressIndicator from './ProgressIndicator';
import PlusOneTooltip from './PlusOneTooltip';
import * as toolTipLabels from './../constants/labels';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Alert } from '@material-ui/lab';
const styles = (theme) => ({
  bodyDiv: {
    marginTop: '10px',
    marginRight: '5%',
    marginLeft: '5%',
    minHeight: '730px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '930px',
    },
  },
  dashboardHeader: {
    marginBottom: '30px'
  },
  dataGrid: {
    backgroundColor: "#FAFAFAFA"
  },
  menu: {
    backgroundColor: "#FAFAFAFA",
    marginTop: "65px",
    marginRight: "10px"
  },
  csvLink:{
    textDecoration: 'none'
  },
  new:{
    borderColor: "#666CFF", 
    width: "130px"
  },
  approved:{
    borderColor: "#35811A", 
    width: "130px"
  },
  rejected:{
    borderColor: "#C10B2B", 
    width: "130px"
  }
});

const NoteStatus = {
  APPROVED: "approved",
  REJECTED: "rejected",
  PENDING: "pending",
  HIGHLIGHTED: "highlighted"
};



class ManagerForm extends React.Component {

  getColumns(view){
    return [
      {
        field: 'recipientName', width: 180, headerName: 'Name',
        valueGetter: this.getFullName,
        sortComparator: this.getSortComparatorFullName
      },
      {
        field: 'createdDate', width: 100, headerName: 'Date', description: 'Date Submitted',
        valueFormatter: (params) => (new Date(params.value).toLocaleDateString("en-US"))
      },
      { field: 'supervisorFullName', width: 180, headerName: 'Manager',},
      { field: 'title', flex: 1, headerName: 'Entry Title' },
      {
        field: 'isHighlighted', width: 75, headerName: 'Focus',
        renderCell: (params) => (
          <div>{this.getHighlighted(params.value)} </div>
        )
      },
      {
        field: 'approvalStatus', width: 160, headerName: 'Status',
        renderCell: (params) => (
          <div>{this.getStatusChip(params.value)} </div>
        )
      },
      {
        field: 'id', width: 90, headerName: 'View',
        renderCell: (params) => (
          <Button component={Link} to={ "/manager/:id/:view".replace(":id", params.value).replace(":view", view) }>View <MoreVert/></Button>
        )
      },
    ];
  }

  sortModel = [
    {
      field: 'approvalStatus',
      sort: 'asc',
    },
    {
      field: 'createdDate',
      sort: 'asc',
    },
  ];

  csvHeaders = [
    { label: "First Name", key: "recipientFirstName" },
    { label: "Last Name", key: "recipientLastName" },
    { label: "Date Submitted", key: "createdDate" },
    { label: "Manager", key: "supervisorFullName" },
    { label: "Focus", key: "isHighlighted" },
    { label: "Status", key: "approvalStatus" },
    { label: "Entry Title", key: "title" },
    { label: "Entry Description", key: "description" }
  ];

  getStatusChip(val) {
    const { classes } = this.props;
    switch (val) {
      case (NoteStatus.APPROVED):
        return (<Chip variant="outlined" icon={<Check style={{color: "#35811A"}}/>} className={classes.approved} label="Approved" />);
      case (NoteStatus.REJECTED):
        return (<Chip variant="outlined" icon={<Close style={{color: "#C10B2B"}}/>} className={classes.rejected} label="Rejected" />);
      default:
        return (<Chip variant="outlined" icon={<NotificationsActive style={{color: "#666CFF"}}/>} className={classes.new} label="New" />);
    }
  }

  getHighlighted(val){
    if(val === 'true'){
      return <PlusOneTooltip title={toolTipLabels.FOCUS_TABLE} placement="top-end" arrow><Star style={{color: "#FFCE21"}}/></PlusOneTooltip>
    }else{
     return <StarBorder/>
    }
  }

  constructor() {
    super();
    //get the view from url path
    let path = window.location.pathname;
    let view = path.substring(path.lastIndexOf("/") + 1);
    this.state = {
      filteredItems: [],
      allItems: [],
      searchValue: '',
      viewType: view,
      loading: false,
      detail: false,
      itemLoading: false,
      modalOpen: false,
      initialUser: false,
      isError: false,
      errMessage: ''
    };
  }

  getFullName(params) {
    return "{0}, {1}"
      .replace("{0}", params.getValue('recipientLastName'))
      .replace("{1}", params.getValue('recipientFirstName'));
  }

  getSortComparatorFullName(v1, v2, param1, param2){
    return (param1.row.recipientLastName + param1.row.recipientFirstName)
      .localeCompare(param2.row.recipientLastName + param2.row.recipientFirstName);
  }

  handleModalClose = (refetch) => {
    const { userInfo } = this.context;
    const { viewType } = this.state;

    this.setState({
      modalOpen: false
    });

    if (refetch)
      this.fetchPlusOnes(userInfo, viewType);
  }

  componentDidMount() {
    const { userInfo } = this.context;
    const { viewType } = this.state;
    
    if (userInfo) {
      this.fetchPlusOnes(userInfo, viewType);
    }
  }

  componentDidUpdate() {
    const { userInfo } = this.context;
    const { initialUser, viewType } = this.state;
    
    if ((userInfo && initialUser && (userInfo.id !== initialUser.id)) || (userInfo && !initialUser)) {
      this.fetchPlusOnes(userInfo, viewType);
    }
  }

  fetchPlusOnes(userInfo, view) {
    const { loading } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
        initialUser: userInfo,
      });

      if(view === 'directreports'){
        getSubordinatesPlusOnes(userInfo.id, (data) => {
          this.setState({
            filteredItems: data,
            allItems: data,
            loading: false
          });
        })
        .catch((error) => {
          console.log("Error getting plus ones: " + error.message);
            this.setState({
              isError: true,
              errMessage: error
            });
        })
      }else if (view === 'fullreportchain'){
        getAllSubordinatesPlusOnes(userInfo.id, (data) => {
          this.setState({
            filteredItems: data,
            allItems: data,
            loading: false
          });
        })
        .catch((error) => {
          console.log("Error getting plus ones: " + error.message);
          this.setState({
            isError: true,
            errMessage: error
          });
      })
      }
    }
  }

  handleMenuClick(view){
    const { userInfo } = this.context;
    this.setState({
      viewType: view,
      searchValue: ''
    });
    this.fetchPlusOnes(userInfo, view);
  }

  handleSearchTextChange (value) {
    const { allItems } = this.state;
    let filtered = allItems.filter(item => {
      let fullName = item.recipientLastName.toLowerCase() + ", " + item.recipientFirstName.toLowerCase();
      let supervisorFullName = item.supervisorFullName ? item.supervisorFullName.toLowerCase() : '';
      return (
        fullName.includes(value.toLowerCase()) || 
        supervisorFullName.includes(value.toLowerCase())
      )
    });
    this.setState({
      filteredItems: filtered,
      searchValue: value
    });
  };

  renderDashboard() {
    const { classes } = this.props;
    const { filteredItems, loading, searchValue, viewType, isError } = this.state;

    return (
      <Grid container>
        <Grid item xs={12} md={2}>
          <MenuList className={classes.menu}>
            <MenuItem component={Link} to={"/"}>Back to Home</MenuItem>
            <MenuItem selected={viewType === 'directreports'}
              to="/manager/directreports"
              component={Link}
              onClick={() => { this.handleMenuClick('directreports') }}
            >
              Direct Reports
            </MenuItem>
            <MenuItem selected={viewType === 'fullreportchain'}
              to="/manager/fullreportchain"
              component={Link}
              onClick={() => { this.handleMenuClick('fullreportchain') }}
            >
              Full Report Chain
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid item xs={12} md={10}>
          <ProgressIndicator loading={loading}>
            <Grid container spacing={1}>
              <Grid item xs={6} >
                <TextField
                  id="outlined-search"
                  type="search"
                  variant="outlined"
                  style={{background: "#9F9F9F"}}
                  fullWidth
                  value={searchValue}
                  placeholder="Search Entries by Name or Manager"
                  onChange={event => { this.handleSearchTextChange(event.target.value) }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6} container justify="flex-end" alignItems="flex-end">
                <CSVLink headers={this.csvHeaders} data={filteredItems} filename={"plusones.xls"} className={classes.csvLink}>
                  <PlusOneTooltip title={toolTipLabels.DOWNLOAD_CSV} placement="right-end" arrow>
                    <Button color="primary"><GetApp />Export</Button>
                  </PlusOneTooltip>
                </CSVLink>
              </Grid>
              
              <Grid item xs={12}>
                <DataGrid
                  className={classes.dataGrid}
                  rows={filteredItems}
                  columns={this.getColumns(viewType)}
                  pageSize={10}
                  sortModel={this.sortModel}
                  autoHeight={true}
                  disableColumnMenu
                />
              </Grid>
            </Grid>
          </ProgressIndicator>
        </Grid>
        <Snackbar open={isError} autoHideDuration={5000} >
          <Alert severity="error">
            Error retrieving data. Please try again later and contact support if the problem persists.
          </Alert>
        </Snackbar>
      </Grid>
    )
  }

  renderDetails(props) {
    return (
      <PlusOneDetailView
        itemId={props.match.params.id}
        viewType={props.match.params.view}
        handleClose={this.handleModalClose}
        returnUrl={"/manager/:view".replace(":view", props.match.params.view)} 
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.bodyDiv}>
        <Typography component="h1" variant="h4" gutterBottom className={classes.dashboardHeader}>
          Manager Dashboard
        </Typography>
        <Switch>
          <Route exact path={["/manager/directreports", "/manager/fullreportchain"]} render={() =>
            this.renderDashboard()
          }>
          </Route>
          <Route path="/manager/:id/:view" render={(props) =>
            this.renderDetails(props)
          }>
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

ManagerForm.contextType = AuthContext
export default withStyles(styles)(ManagerForm);
