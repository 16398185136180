import { 
  Grid, 
  Button, 
  withStyles, 
  TextField, 
  MenuItem, 
  FormLabel, 
  Typography, 
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  Box
} from "@material-ui/core";
import { Star, StarBorder } from '@material-ui/icons';
import React from 'react'
import { getPlusOne, updateStatus } from "../api/PlusOneEntryAPI";
import { approvalReasons } from "../constants/approvalReasons";
import { rejectionReasons } from "../constants/rejectionReasons";
import { highlightReasons } from "../constants/highlightReasons";
import ProgressIndicator from "./ProgressIndicator";
import PlusOneTooltip from './PlusOneTooltip';
import * as toolTipLabels from './../constants/labels';
import { Link, Redirect } from "react-router-dom";

const styles = (theme) => ({
  paper: {
    backgroundColor: '#fff',
    border: '1px solid rgba(224,224,224,1)',
    boxShadow: theme.shadows[2],
    padding: '20px',
    paddingLeft: '10%',
    paddingRight: '10%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: "5%",
      paddingRight: "5%"
    }
  },
  innerFrame: {
    backgroundColor: '#fff',
    border: '1px solid rgba(224,224,224,1)',
    padding: '10px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 35px',
      lineHeight: '26px',
    },
    fontSize: '14px',
    borderRadius: '32px',
    borderColor: 'rgba(204, 204, 204, 1)',
  },
  helperLabel: {
    marginBottom: '-10px',
  },
  helperText: {
    textAlign: "right"
  },
  descContent: {
    marginBottom: '20px'
  },
  occurredOn: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    }
  }
});

const FocusRadio = withStyles({
  root: {
    '&$checked': {
      color: '#FFCE21',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

class PlusOneDetailView extends React.Component {

  constructor() {
    super();
    this.state = {
      managerAction: '',
      approvalReason: '',
      managerComment: '',
      isHighlighted: '',
      highlightReason: '',
      submitPending: false,
      submitSuccessful: false,
      loading: false,
      item: false
    };
  }

  handleChange(event) {
    let key = event.target.name;
    let value = event.target.value;
    this.setState({
      [key]: value
    });

    //reset dependent fields on manager action change
    if (key === 'managerAction') {
      this.setState({
        approvalReason: '',
        isHighlighted: '',
        highlightReason: ''
      });
    }

    if (key === 'isHighlighted') {
      this.setState({
        highlightReason: ''
      });
    }
  };

  handleSubmit(event) {
    event.preventDefault();
    const { managerAction, approvalReason, managerComment, isHighlighted, highlightReason, item } = this.state;
    const { handleClose } = this.props;
    const payload = {
      approvalStatus: managerAction,
      approvalReason,
      managerComment,
      isHighlighted,
      highlightReason
    }

    this.setState({
      submitPending: true
    });

    updateStatus(item.id, payload, () => {
      this.setState({
        submitPending: false,
        submitSuccessful: true,
        managerAction: '',
        approvalReason: '',
        managerComment: '',
        isHighlighted: '',
        highlightReason: ''
      });

      handleClose(true);
    })
  }

  fetchPlusOne(id) {
    this.setState({
        loading: true
    });

    getPlusOne(id, (data) => {
        this.setState({
        item: data,
        loading: false
        });
    });
  }

  componentDidMount() {
    const { itemId } = this.props;

    this.fetchPlusOne(itemId);
  }

  toTitleCase(input) {
    return (input) ? input.replace(/\b[a-z]/g, (x) => x.toUpperCase()) : '';
  }

  renderModalBody() {
    const { classes, readOnly, viewType } = this.props;
    const { managerAction, approvalReason, managerComment, isHighlighted, highlightReason, submitPending, item, loading } = this.state;

    const isReadOnly = (item.approvalStatus || readOnly || viewType === 'fullreportchain' ) ? true : false;

    return (
      <div className={classes.paper}>
        <ProgressIndicator loading={loading}>
          <Grid container spacing={2} justify="center" >
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h5" gutterBottom>
                <b>{item.title}</b>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              {item.recipientFirstName} {item.recipientLastName}
            </Grid>
            <Grid item xs={12} sm={2} className={classes.occurredOn}>
              <b>Occurred On:</b> {new Date(item.plusOneDate).toLocaleDateString("en-US")}
            </Grid>
            <Grid container item xs={12} sm={12} className={classes.innerFrame} spacing={3}>
              <Grid item xs={12} sm={4}>
                <b>Date Submitted:</b> {new Date(item.createdDate).toLocaleDateString("en-US")}
              </Grid>
              <Grid item xs={12} sm={4}>
                <b>Support Type:</b> {item.type}
              </Grid>
              <Grid item xs={12} sm={4}>
                <b>Submitted By:</b> {item.submitterFirstName} {item.submitterLastName}
              </Grid>
              <Grid item xs={12} sm={8}>
                <b>Customers:</b> {item.customerType}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.descContent}>
              {item.description}
            </Grid>
            <form autoComplete="off" onSubmit={(event) => this.handleSubmit(event)}>
              {(isReadOnly) ?
                this.renderReadOnlyFields(item.approvalStatus, item.approvalReason, item.managerComment, item.isHighlighted, item.highlightReason) :
                this.renderFormFields(managerAction, approvalReason, managerComment, isHighlighted, highlightReason, submitPending)}

            </form>
          </Grid>
        </ProgressIndicator>
      </div>
    );

  }

  renderReadOnlyFields(mgrAction, appReason, mgrComment, isHighlighted, highlightReason) {
    const { classes, returnUrl } = this.props;

    return (
      <Grid container xs={12} spacing={3} item>
        <Grid item xs={12} sm={6}>
          <b>Approval Status:</b> {this.toTitleCase(mgrAction)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Approval Reason:</b> {this.toTitleCase(appReason)}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Leadership Focus:</b> 
          {(isHighlighted === 'true') ? <span> Yes</span> : <span> No</span>}
        </Grid>
        <Grid item xs={12} sm={6}>
          <b>Focus Reason:</b> {highlightReason}
        </Grid>
        <Grid item xs={12} sm={12}>
          {mgrComment && 
            <span><b>Comments:</b> {mgrComment}</span>
          }
        </Grid>
        <Grid container item xs={12} sm={12} justify="flex-end">
          <Button className={classes.button} color="secondary" variant="contained" component={Link} to={returnUrl}>
              Close
          </Button>       
        </Grid>
      </Grid>
    )
  }

  renderFormFields(mgrAction, appReason, mgrComment, isHighlighted, highlightReason, submitPending) {
    const { classes, returnUrl } = this.props;
    return (
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Divider variant="middle"/>
        </Grid>
        <Grid item xs={12} align="left">
          <Box mt={4} mb={4}>
            <Typography component="h1" variant="h5">
              Please qualify this Plus One and let us know if it deserves recognition.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" margin='normal' required>
            <PlusOneTooltip title={toolTipLabels.IS_PLUS_ONE} placement="top-end" arrow>
              <FormLabel component="legend">Was this a Plus One?</FormLabel>
            </PlusOneTooltip>
            <RadioGroup aria-label="managerAction" name="managerAction" value={mgrAction} onChange={(event) => this.handleChange(event)} row>
              <FormControlLabel value="approved" control={<Radio required/>} label="Approve" />
              <FormControlLabel value="rejected" control={<Radio required/>} label="Reject" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel component="legend" className={classes.helperLabel} required>Let us know why</FormLabel>
          <TextField
            variant="outlined"
            margin="normal"
            onChange={(event) => this.handleChange(event)}
            value={appReason}
            fullWidth
            required
            id="mgr-reason-select"
            name="approvalReason"
            select
          >
            {mgrAction === 'rejected' ? rejectionReasons.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )) : mgrAction === 'approved' ? approvalReasons.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )) : <MenuItem key="" value="" />
            }
          </TextField>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" margin='normal' required>
            <PlusOneTooltip title={toolTipLabels.SHOULD_FOCUS} placement="top-end" arrow>
              <FormLabel component="legend">Should leadership focus on this?</FormLabel>
            </PlusOneTooltip>
            <RadioGroup aria-label="isHighlighted" name="isHighlighted" value={isHighlighted} onChange={(event) => this.handleChange(event)} row>
              <FormControlLabel value="true" control={<FocusRadio icon={<StarBorder />} checkedIcon={<Star />} required/>} label="Yes" />
              <FormControlLabel value="false" control={<Radio required />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {isHighlighted === 'true' &&
          <Grid item xs={12} sm={6}>
            <FormLabel component="legend" className={classes.helperLabel} required >Let us know why</FormLabel>
            <TextField
              variant="outlined"
              margin="normal"
              onChange={(event) => this.handleChange(event)}
              value={highlightReason}
              fullWidth
              required
              id="mgr-reason-select"
              name="highlightReason"
              select
            >
              {highlightReasons.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <FormLabel component="legend" className={classes.helperLabel} >Comments (optional)</FormLabel>
          <PlusOneTooltip title={toolTipLabels.COMMENTS} placement="top-end" arrow>
            <TextField
              variant="outlined"
              margin="normal"
              onChange={(event) => this.handleChange(event)}
              value={mgrComment}
              fullWidth
              multiline
              rows={6}
              id="managerComment"
              name="managerComment"
              autoComplete="managerComment"
              inputProps={{
                maxLength: 1000,
              }}
              helperText={`${mgrComment.length}/${1000}`}
              FormHelperTextProps={{
                className: classes.helperText
              }}
            />
          </PlusOneTooltip>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle"/>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Box mt={1} >
            <FormLabel component="legend" className={classes.helperText} >All submissions are final</FormLabel>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={12} justify="flex-end">
          <Button className={classes.button} color="secondary" variant="contained" component={Link} to={returnUrl}>
              Cancel
          </Button>
          &nbsp;
          <Button className={classes.button} color="primary" variant="contained" type="submit">
              <ProgressIndicator type="small" color="secondary" loading={submitPending}>
                  Submit
              </ProgressIndicator>
          </Button>        
        </Grid>
      </Grid>
    )
  }
  render() {
    const { submitSuccessful } = this.state;
    const { returnUrl } = this.props;
    if(submitSuccessful)
        return (<Redirect to={returnUrl} />);
    else
        return this.renderModalBody(); 
  }
}

export default withStyles(styles)(PlusOneDetailView);
