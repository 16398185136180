import { withStyles} from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const PlusOneTooltip = withStyles((theme) => ({
  tooltip: {
    padding: '20px 20px 20px 20px',
    backgroundColor: '#444444',
    borderRadius: '4px',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'Montserrat',
    fontWeight: 10,
    boxShadow: '4px 2px 5px gray'
  },
  arrow: {
    color: '#444444',
  }
}))((props) => <Tooltip {...props} />);
export default PlusOneTooltip;
