import React, { useState } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import { GetApp } from '@material-ui/icons';
import { Button, FormLabel, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import ProgressIndicator from './ProgressIndicator';
import { getPlusOneByMonth } from '../api/ReportAPI';
import { months } from '../constants/months';
import { CSVLink } from 'react-csv';
import PlusOneTooltip from './PlusOneTooltip';
import * as toolTipLabels from './../constants/labels';
const YEARS_TO_SHOW = 5;

const useStyles = makeStyles((theme) => ({
  bodyDiv: {
    marginTop: '10px',
    marginRight: '5%',
    marginLeft: '5%',
    minHeight: '730px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '930px',
    },
  },
  csvLink:{
    textDecoration: 'none'
  },
  dataGrid: {
    backgroundColor: "#FAFAFA"
  },
  headingGrid: {
    backgroundColor: "#FAFAFA",
    marginTop: "0.5rem"
  }
}));

const sortModel = [
  {
    field: 'createdDate',
    sort: 'asc',
  },
];

const csvHeaders = [
    { label: "First Name", key: "recipientFirstName" },
    { label: "Last Name", key: "recipientLastName" },
    { label: "E-mail", key: "recipientEmail"},
    { label: "Submitter First", key: "submitterFirstName" },
    { label: "Submitter Last", key: "submitterLastName" },
    { label: "Submitter E-mail", key: "submitterEmail" },
    { label: "Department", key: "recipientDepartment" },
    { label: "Date Submitted", key: "createdDate" },
    { label: "Manager", key: "supervisorFullName" },
    { label: "Focus", key: "isHighlighted" },
    { label: "Status", key: "approvalStatus" },
    { label: "Entry Title", key: "title" },
    { label: "Entry Description", key: "description" },
    { label: "Customer Type", key: "customerType" },
    { label: "Submitting As", key: "submittingAs" },
    { label: "Plus One Date", key: "plusOneDate" }
  ];

const gridColumns = [
    {
      field: 'submitterName', width: 200, headerName: 'Submitted By',
      valueGetter: (params) => (`${params.getValue("submitterLastName")}, ${params.getValue("submitterFirstName")}`)
    },
    {
        field: 'recipientName', width: 200, headerName: 'Recipient',
        valueGetter: (params) => (`${params.getValue("recipientLastName")}, ${params.getValue("recipientFirstName")}`)
    },    
    {
      field: 'createdDate', width: 200, headerName: 'Date Submitted',
      valueFormatter: (params) => (new Date(params.value).toLocaleDateString("en-US"))
    },
    { field: 'title', flex: 1, headerName: 'Entry Title' },
  ];

const padWithZero = (input) => {
    return (input.toString().length < 2) ? "0" + input : input;
}

const PlusOneReport = () => {
    const currDate = new Date();
    const currMonth = (currDate.getMonth() + 1).toString();
    const currentYear = currDate.getFullYear();
    
    const currentMonth = padWithZero(currMonth);

    const [gridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedEndYear, setSelectedEndYear] = useState(currentYear);
    const [selectedEndMonth, setSelectedEndMonth] = useState(currentMonth);

    const handleSearchClick = () => {
        setLoading(true);
        // Add one month to the "end date" to catch everything during that month
        const selEndYear = (selectedEndMonth === "12") ? parseInt(selectedEndYear) + 1 : selectedEndYear;
        const selEndMonth = (selectedEndMonth === "12") ? "01" : padWithZero(parseInt(selectedEndMonth) + 1);
        getPlusOneByMonth(selectedYear, selectedMonth, selEndYear, selEndMonth)
            .then((response) => { setGridData(response.data); setLoading(false); });
    }

    const { bodyDiv, dataGrid, csvLink, headingGrid } = useStyles();

    const buildYearOptions = () => {
        const currYear = new Date().getFullYear();
        let retVal = [];

        for(let x = 0; x < YEARS_TO_SHOW; x++) {
            retVal.push({
                "text" : currYear - x,
                "value" : currYear - x,
            });
        }

        return retVal;
    }
    
    const years = buildYearOptions();
    return (
        <div className={bodyDiv}>
        <Typography component="h1" variant="h4" gutterBottom>
          Plus One Reporting
        </Typography>
        <Grid container spacing={3} className={headingGrid}>
        <Grid item xs={3}>
            <FormLabel>Start Month</FormLabel>
            <TextField
                variant="outlined"
                margin="normal"
                value={selectedMonth}
                onChange={ (e) => { setSelectedMonth(e.target.value); }}
                fullWidth
                required
                id="year-select"
                name="selectedYear"
                select
            >
                { months.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
                ))
                }
            </TextField>
          </Grid>
        <Grid item xs={3}>
            <FormLabel>Start Year</FormLabel>
            <TextField
                variant="outlined"
                margin="normal"
                value={selectedYear}
                onChange={ (e) => { setSelectedYear(e.target.value); }}
                fullWidth
                required
                id="year-select"
                name="selectedYear"
                select
            >
                { years.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
                ))
                }
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>End Month</FormLabel>
            <TextField
                variant="outlined"
                margin="normal"
                value={selectedEndMonth}
                onChange={ (e) => { setSelectedEndMonth(e.target.value); }}
                fullWidth
                required
                id="year-select"
                name="selectedEndYear"
                select
            >
                { months.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
                ))
                }
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <FormLabel>End Year</FormLabel>
            <TextField
                variant="outlined"
                margin="normal"
                value={selectedEndYear}
                onChange={ (e) => { setSelectedEndYear(e.target.value); }}
                fullWidth
                required
                id="year-select"
                name="selectedEndYear"
                select
            >
                { years.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.text}
                </MenuItem>
                ))
                }
            </TextField>
          </Grid>

          <Grid item container xs={12} justify="flex-end">
            <Button
                variant="contained"
                onClick={handleSearchClick}>
                Search      
            </Button>
            &nbsp;&nbsp;&nbsp;
            <CSVLink headers={csvHeaders} data={gridData} filename={"plusones.xls"} className={csvLink}>
                <PlusOneTooltip title={toolTipLabels.DOWNLOAD_CSV_REPORT} placement="right-end" arrow>
                <Button variant="contained" disabled={ (gridData.length === 0)}><GetApp />Export</Button>
                </PlusOneTooltip>
            </CSVLink>
          </Grid>
          <Grid container xs={12}>
            <ProgressIndicator loading={loading}>
                <DataGrid
                    className={dataGrid}
                    rows={gridData}
                    columns={gridColumns}
                    pageSize={10}
                    sortModel={sortModel}
                    autoHeight={true} />
            </ProgressIndicator>
            </Grid>
        </Grid>
      </div>
    );
}

export default PlusOneReport;