import React from 'react';
import { AuthContext } from '../../context/authContext';

class OauthLogout extends React.Component {

    componentDidMount() {
        const auth = this.context;

        auth.logout();
        window.location.replace(auth.getLogoutUrl());
    }
    render() { 
        return null;
    }
}

OauthLogout.contextType = AuthContext;

export default OauthLogout;