import React from 'react'
import { AuthContext } from '../context/authContext';
import { DataGrid } from '@material-ui/data-grid';
import { MoreVert } from '@material-ui/icons';
import { Button, Typography, withStyles } from '@material-ui/core';
import PlusOneReadOnlyView from './PlusOneReadOnlyView';
import ProgressIndicator from './ProgressIndicator';
import { Link, Route } from 'react-router-dom';
import { getMyPlusOnes } from '../api/PlusOneEntryAPI';

const styles = (theme) => ({
  bodyDiv: {
    marginTop: '10px',
    marginRight: '5%',
    marginLeft: '5%',
    minHeight: '730px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '930px',
    },
  },
  dataGrid: {
    backgroundColor: "#FAFAFAFA"
  },
});

const sortModel = [
  {
    field: 'createdDate',
    sort: 'asc',
  },
];

class MyPlusOneForm extends React.Component {

  gridColumns = [
    {
      field: 'submitterName', width: 200, headerName: 'Submitted By',
      valueGetter: this.getFullName,
      sortComparator: this.getSortComparatorFullName
    },
    {
      field: 'createdDate', width: 200, headerName: 'Date Submitted',
      valueFormatter: (params) => (new Date(params.value).toLocaleDateString("en-US"))
    },
    { field: 'title', flex: 1, headerName: 'Entry Title' },
    {
      field: 'id', width: 100, headerName: 'View',
      renderCell: (params) => (
        <Button component={Link} to={ "/myplusone/:id".replace(":id", params.value) }>View <MoreVert/></Button>
      )
    },
  ];

  constructor() {
    super();
    this.state = {
      items: [],
      loading: false,
      detail: false,
      itemLoading: false,
      modalOpen: false,
      initialUser: false
    };
  }

  getFullName(params) {
    return "{0}, {1}"
      .replace("{0}", params.getValue('submitterLastName'))
      .replace("{1}", params.getValue('submitterFirstName'));
  }

  getSortComparatorFullName(v1, v2, param1, param2){
    return (param1.data.recipientLastName + param1.data.recipientFirstName)
      .localeCompare(param2.data.recipientLastName + param2.data.recipientFirstName);
  }

  componentDidMount() {
    const { userInfo } = this.context;

    if (userInfo) {
      this.fetchPlusOnes(userInfo);
    }
  }

  componentDidUpdate() {
    const { userInfo } = this.context;
    const { initialUser } = this.state;

    if ((userInfo && initialUser && (userInfo.id !== initialUser.id)) || (userInfo && !initialUser)) {
      this.fetchPlusOnes(userInfo);
    }
  }

  fetchPlusOnes(userInfo) {
    const { loading } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
        initialUser: userInfo
      });

      getMyPlusOnes(userInfo.id, (data) => {
        this.setState({
          items: data,
          loading: false
        });
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { items, loading } = this.state;

    return (
      <div className={classes.bodyDiv}>
        <Typography component="h1" variant="h4" gutterBottom>
          My Approved Plus Ones
        </Typography>
        <Route exact path="/myplusone">
            <ProgressIndicator loading={loading}>
                <DataGrid
                    className={classes.dataGrid}
                    rows={items}
                    columns={this.gridColumns}
                    pageSize={10}
                    sortModel={sortModel}
                    autoHeight={true} />
            </ProgressIndicator>
        </Route>
        <Route path="/myplusone/:id" render={ (props) => 
            <PlusOneReadOnlyView
                itemId={props.match.params.id}
                returnTo="/myplusone" />
        }>
        </Route>
      </div>
    );
  }
}

MyPlusOneForm.contextType = AuthContext
export default withStyles(styles)(MyPlusOneForm);