import NavBar from '../components/NavBar';
import Copyright from '../components/Copyright';


const BasicView = ({children}) => {
  return (
    <div>
      <NavBar />
      {children}
      <Copyright />
    </div>
  )
}

export default BasicView;