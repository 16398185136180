import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { AuthContext } from '../context/authContext';
import ProgressIndicator from './ProgressIndicator';

class UserInfo extends React.Component {
  renderUserName(userInfo) {
    if(userInfo)
      return "{0}, {1}"
        .replace("{0}",userInfo.lastName)
        .replace("{1}", userInfo.firstName)
    else
      return "";
  }

  render() {
    const {userInfo, hasError }  = this.context;
    if(hasError) {
      return(
        <div>
          #
          <Snackbar open={hasError} autoHideDuration={5000} >
            <Alert severity="error">
              Error retrieving user information. Please try again later
            </Alert>
          </Snackbar>
        </div>)
    }
    else {
      return (
        <ProgressIndicator type="small" loading={!userInfo} color="#FAFAFAFA">
          { this.renderUserName(userInfo) }
        </ProgressIndicator>
      );
    }
  }
}

UserInfo.contextType = AuthContext;
export default UserInfo