import { APIUtils } from './APIUtils';

const getEmployeeInfo = (callback) => {
  return APIUtils.get('/api/employee').then((res) => { callback(res.data); });
}

const searchEmployeesByFullName = (fullName, callback) => {
  return APIUtils.get('/api/employeesearch?name={}'.replace('{}', fullName)).then((res) => { callback(res.data) });
}

export {
  searchEmployeesByFullName, 
  getEmployeeInfo
};
