import { AuthContext } from '../../context/authContext';
import React from 'react';


class OauthSend extends React.Component {
    componentDidMount() {
        const auth = this.context;
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get("redirect");

        window.location.replace(auth.getLoginUrl(redirect));
    }

    render() {
        return null;
    }
}

OauthSend.contextType = AuthContext;
export default OauthSend;
