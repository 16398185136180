const { CircularProgress, makeStyles } = require("@material-ui/core")

const useStyles = makeStyles((theme) => ({
indicator: {
    width: '100%', 
    minHeight: '200px', 
    display:'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  indicatorSmall: {
    display:'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  }
}));

const ProgressIndicator = (props) => {
    const classes = useStyles();
    const className = (props.type === "small") ? classes.indicatorSmall : classes.indicator;
    const col = props.color ? props.color : "primary";
    const {loading} = props;

    if(loading) {
        return (
            <div className={className}>
                <CircularProgress color={col} />
            </div>
        );
    }
    else {
        return (props.children);
    }
}

export default ProgressIndicator;