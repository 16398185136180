import { Grid, Button, withStyles, Typography } from "@material-ui/core";
import React from 'react'
import { getPlusOne } from "../api/PlusOneEntryAPI";

import ProgressIndicator from "./ProgressIndicator";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  paper: {
    backgroundColor: '#fff',
    border: '1px solid rgba(224,224,224,1)',
    boxShadow: theme.shadows[2],
    padding: '20px',
    paddingLeft: '10%',
    paddingRight: '10%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: "5%",
      paddingRight: "5%"
    }
  },
  innerFrame: {
    backgroundColor: '#fff',
    border: '1px solid rgba(224,224,224,1)',
    padding: '10px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 35px',
      lineHeight: '26px',
    },
    fontSize: '14px',
    borderRadius: '32px',
    borderColor: 'rgba(204, 204, 204, 1)',
  },
  helperLabel: {
    marginBottom: '-10px',
  },
  descContent: {
    marginBottom: '20px'
  },
  occurredOn: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    }
  }
});

class PlusOneReadOnlyView extends React.Component {

  constructor() {
    super();
    this.state = {
      managerAction: '',
      approvalReason: '',
      managerComment: '',
      submitPending: false,
      submitSuccessful: false,
      loading: false,
      item: false
    };
  }

  fetchPlusOne(id) {
    this.setState({
        loading: true
    });

    getPlusOne(id, (data) => {
        this.setState({
        item: data,
        loading: false
        });
    });
  }

  componentDidMount() {
    const { itemId } = this.props;

    this.fetchPlusOne(itemId);
  }

  renderModalBody() {
    const { classes, returnTo } = this.props;
    const { item, loading } = this.state;

    const itemType = item.type? item.type.charAt(0).toUpperCase() + item.type.slice(1) : '';

    return (
    <div className={classes.paper}>
        <ProgressIndicator loading={loading}>
            <Grid container spacing={2} justify="center" >
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h5" gutterBottom>
                        <b>{item.title}</b>
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                    {item.recipientFirstName} {item.recipientLastName}
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.occurredOn}>
                    <b>Occurred On:</b> {new Date(item.plusOneDate).toLocaleDateString("en-US")}
                    </Grid>
                    <Grid container item xs={12} sm={12} className={classes.innerFrame} spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <b>Date Submitted:</b> {new Date(item.createdDate).toLocaleDateString("en-US")}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <b>Support Type:</b> {itemType}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <b>Submitted By:</b> {item.submitterFirstName} {item.submitterLastName}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <b>Customers:</b> {item.customerType}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.descContent}>
                    {item.description}
                    </Grid>
                    <Grid container item xs={12} sm={12} justify="flex-end">
                    <Button className={classes.button} color="secondary" variant="contained" component={Link} to={returnTo}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        </ProgressIndicator>
    </div>
    );
  }

  render() {
    return this.renderModalBody(); 
  }
}

export default withStyles(styles)(PlusOneReadOnlyView);
