export const approvalReasons = [
    {
      value: 'Customer Experience',
      label: 'Customer Experience'
    },
    {
      value: 'Reliability & Power Quality',
      label: 'Reliability & Power Quality'
    },
    {
      value: 'Affordability',
      label: 'Affordability'
    },
    {
      value: 'Promoting Brand Identity',
      label: 'Promoting Brand Identity'
  }
]