import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
let theme = createMuiTheme({
  palette: {
    background: {
      default: '#FAFAFAFA'
    },
    primary: {
      main: '#212121'
    }
  },
  typography: {
    fontFamily: [
      'Montserrat', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      'sans-serif'
    ].join(','),
    button: {
      letterSpacing: '2px',
    }
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    //header and footer background and text color on DataGrid component
    MuiDataGrid: {
      root: {
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: '#212121',
          color: '#FDFDFD'
        },
        '& .MuiDataGrid-iconSeparator': {
          color: '#212121'
        },
        '& .MuiDataGrid-sortIcon':{
          color: '#FDFDFD'
        },
        '& .MuiDataGrid-footer':{
          backgroundColor: '#212121'
        },
        '& .MuiTablePagination-caption':{
          color: '#FDFDFD'
        },
        '& .MuiTablePagination-actions':{
          color: '#FDFDFD'
        },
        '& .Mui-disabled':{
          color: 'gray'
        }
      }
    }
  }
});
theme = responsiveFontSizes(theme);
export default theme;