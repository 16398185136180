import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, FormLabel, CircularProgress } from "@material-ui/core";
import PlusOneTooltip from './PlusOneTooltip';
import * as toolTipLabels from './../constants/labels';
import { searchEmployeesByFullName } from '../api/EmployeeAPI';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  helperLabel: {
  marginBottom: '-10px',
}}));

const EmployeeSearch = (props) => {
  const classes = useStyles();
  const {onChangeHandler} = props;  
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  

  const handleTextChange = async (value) => {
    searchEmployeesByFullName(value, (data) => {
      setOptions(data);
    });

  };

  const getOptionLabel = (option) => {
    let label = option.fullNameDisplay;
    if(option.title) {
      label = label + " (" + option.title + ")";
    }
    return label;
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div>
      <FormLabel component="legend" className={classes.helperLabel}>Employee Full Name</FormLabel>  
      <PlusOneTooltip title={toolTipLabels.EMP_SEARCH} placement="top-end" arrow>
        <Autocomplete
          onChange={onChangeHandler}
          id="search-employee"
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => option.fullName === value.fullNameDisplay}
          getOptionLabel={getOptionLabel}
          options={options}
          loading={loading}
          renderInput={params => (
            
            <TextField
              {...params}
              placeholder="Start Typing Last Name to Search"
              variant="outlined"
              margin="normal"
              onChange={event => {
                // dont fire API if the user deletes or hasn't entered anything
                if (event.target.value !== "" || event.target.value !== null) {
                  handleTextChange(event.target.value);
                }
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </PlusOneTooltip>
    </div>
  );
}

export default EmployeeSearch;
