import React from 'react';
import { AuthContext } from '../../context/authContext';
import { Redirect } from 'react-router-dom';

class OauthReceive extends React.Component {
    componentDidMount() {
        const auth = this.context;
        const params = new URLSearchParams(window.location.search);
        this.redirectUrl = params.get("state") ? params.get("state") : "/";

        auth.receiveLogin();
    }

    render() {
        const auth = this.context;
        
        if(auth.user)
            return (<Redirect to={this.redirectUrl}></Redirect>);
        else if(auth.hasError) 
                return (
                    <div>
                        <div>Error retrieving User Information!</div>
                        { (auth.errorType === "access_denied") ?
                            <div>If you logged in using your full e-mail address, please re-login using only your Entergy username</div>
                            : <div>An unknown error occurred</div>
                        }
                    </div>)
        else
            return (<div>Fetching User Info...</div>);
    }
}

OauthReceive.contextType = AuthContext;
export default OauthReceive;