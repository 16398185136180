import  { Typography, Link } from '@material-ui/core';

/**
 * Copyright component.
 */
const Copyright = () => {
  return (
    <div style={{marginTop: '100px', paddingBottom: '20px'}}> 
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link underline="always" href="https://keystringlabs.entergy.com/" target="_blank" rel="noreferrer">
          KeyString Labs by Entergy
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      {process.env.REACT_APP_ENV !== 'prod' && 
        <Typography variant="body2" color="textSecondary" align="center">
          {'Environment: ' + process.env.REACT_APP_ENV}
        </Typography>
      }
    </div>
  );
}

export default Copyright;