import { APIUtils } from './APIUtils';

const createPlusOne = async (payload) => {
    return await APIUtils.post('/api/note/', transformPayload(payload));
}

const getSubordinatesPlusOnes = (supervisorEin, callback) => {
  return APIUtils.get("/api/getEmployeeNotes?ein={}".replace("{}", supervisorEin)).then((res) => { callback(res.data) });
}

const getAllSubordinatesPlusOnes = (ein, callback) => {
  return APIUtils.get("/api/getAllEmployeeNotes?ein={}".replace("{}", ein)).then((res) => { callback(res.data) });
}

const getMyPlusOnes = (ein, callback) => {
  return APIUtils.get("/api/getMyNotes?ein={}".replace("{}", ein)).then((res) => { callback(res.data) });
}

const getPlusOne = (id, callback) => {
    return APIUtils.get("/api/note/{}".replace("{}", id)).then((res) => { callback(res.data)});
}

const updateStatus = (id, payload, callback) => {
    return APIUtils.post("/api/notestatus/{}".replace("{}", id), payload).then((res) => { callback(res.data)});
}

const transformPayload = (payload) => {
  let transformedPayload = {
    submittingAs: payload.submittingAs,
    title: payload.plusOneTitle,
    description: payload.plusOneDescription,
    type: payload.plusOneType,
    customerType: payload.customerType,
    plusOneDate: new Date(payload.plusOneDate).toISOString(),
    createdDate: new Date().toISOString(),
    submitterFirstName: payload.submitterFirstName,
    submitterLastName: payload.submitterLastName,
    submitterEin: payload.submitterEIN,
    submitterEmail: payload.submitterEmail,
    recipientFirstName: payload.employeeFirstName,
    recipientLastName: payload.employeeLastName,
    recipientEin: payload.employeeEIN,
    recipientEmail: payload.employeeEmail,
    recipientDepartment: payload.employeeDepartment,
    supervisorEin: payload.supervisorEin,
    supervisorEmail: payload.employeeManagerEmail,
    supervisorFullName: payload.employeeManager
  };

  return JSON.stringify(transformedPayload);
}

export {createPlusOne, getSubordinatesPlusOnes, getPlusOne, updateStatus, getMyPlusOnes, getAllSubordinatesPlusOnes};
