export const rejectionReasons = [
  {
    value: 'No Clear Impact',
    label: 'No Clear Impact'
  },
  {
    value: 'Not an Entergy Customer',
    label: 'Not an Entergy Customer'
  },
  {
    value: 'Part of Normal Job Duties',
    label: 'Part of Normal Job Duties'
  },
  {
    value: 'Other - See Comments',
    label: 'Other - See Comments'
  }
]