import PlusOneReport from '../components/PlusOneReport';
import BasicView from './BasicView';
const PlusOneReportView = () => {
  return (
    <div style={{backgroundColor: "#DBDBDB" }}>
      <BasicView>
        <PlusOneReport />
      </BasicView>
    </div>
  )
}

export default PlusOneReportView;