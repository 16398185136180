
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
}
from '@material-ui/core';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Confetti from 'react-confetti'

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.up('sm')]: {
      padding: '16px 35px',
      lineHeight: '26px',
    },
    fontSize: '14px',
    borderRadius: '32px',
    borderColor: 'rgba(204, 204, 204, 1)',
  }
}));
const PlusOneSuccessDialog = (props) => {
  const classes = useStyles();
  const { onClose, open } = props;


  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Thank you!</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          The Plus One Program aims to highlight where we are exceeding customer expectations today to better shape how we define customer centricity for tomorrow.
        </Typography>
      </DialogContent>
      <DialogActions>
          <Button 
            className={classes.button}
            name="submitAnother"
            autoFocus 
            onClick={onClose} 
            size="large" 
            color="primary" 
            variant="contained">
              Submit Another
          </Button>
          <Button 
            className={classes.button}
            name="logout"
            onClick={onClose} 
            size="large" 
            color="primary" 
            variant="contained" 
            component={RouterLink} to="/logout">
              Log Out
          </Button>
        </DialogActions>
        <Confetti
          width={Window.width}
          height={Window.height}
          numberOfPieces={500}
          recycle={false}
        />
    </Dialog>
  );
}


export default PlusOneSuccessDialog;
